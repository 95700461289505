import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import { AuthenticationContext, Button, getCurrentUserId, LocalizationSettings, Result, useMemoCompare, UserSettingsProvider } from '@rhim/react';
import { RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto, RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto } from '@rhim/rest/customerManagement';
import { usePrivileges } from '@rhim/sdk/customerManagement';
import { activateFeatures, ensure, hasElements, isDefined } from '@rhim/utils';
import { useQueryClient } from '@tanstack/react-query';
import { intersection } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useRouteLoaderData, useSearchParams } from 'react-router-dom';
import shortHash from 'shorthash2';

import { API } from '../../api/customerManagement';
import { AppContextProvider } from '../../app/AppContext';
import { environment } from '../../environments/environment';
import { useUserInfo } from '../../hooks/useUserInfo';
import { useVessels } from '../../hooks/useVessels';
import { getSelectedCustomer } from '../../lib';
import { Api, authClient, PARAMS } from '../../utilities';
import { HMD_PRIVILEGES } from '../../utilities/privileges';
import { RouteId, Tenant } from './TenantLoader';

export type TenantProviderType = {
  customer: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto;
  selectCustomer: (customer: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto) => void;
  search: string;
};

export const TenantContext = React.createContext<(Tenant & TenantProviderType) | null>(null);

export const TenantProvider: React.FunctionComponent<React.PropsWithChildren> = () => {
  const [onBeforeSelectedCustomerChange, setOnBeforeSelectedCustomerChange] = React.useState<(newCustomerId: UUID) => Promise<boolean>>();
  const tenant = useRouteLoaderData(RouteId.Root);
  const { t } = useTranslation(['app']);
  const { account, user } = tenant;
  const queryClient = useQueryClient();
  const userId = getCurrentUserId(account);
  const userQueryKey = useUserInfo.getKey(userId);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Seed the selected customer with the available customer, but allow the user to change customers if they have more than one.
  const [selectedCustomer, selectCustomer] = React.useState<RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto>(() =>
    getSelectedCustomer(user.customers ?? [])
  );

  const vessels = ensure(
    useVessels(selectedCustomer.customerId as UUID, undefined, undefined, {
      configuration: {
        enabled: isDefined(selectedCustomer.customerId),
      },
    }).data,
    `Attempted to fetch vessels before the customer was selected. Make sure ${TenantProvider.name} was rendered after tenantLoader obtained a valid customer ID.`
  );
  useEffect(() => {
    Api.setBearerToken(account.bearerAuthentication);
  }, [account]);

  useEffect(() => {
    const customerPortalCustomerId = searchParams.get(PARAMS.CUSTOMER_PORTAL_CUSTOMER_ID);
    const customerId = searchParams.get(PARAMS.CUSTOMER_ID);
    // Handle urls that come from Customer Portal, which includes the customer portal customer ID.
    // Map the customer portal customer ID to the HMD customer and pre-select it.
    if (isDefined(customerPortalCustomerId)) {
      const foundCustomer = user.customers?.find((customer) => customer.customerPortalCustomerId === customerPortalCustomerId);
      if (foundCustomer) {
        navigate(`${location.pathname}?${PARAMS.CUSTOMER_ID}=${shortHash(foundCustomer.customerId)}`);
        selectCustomer(foundCustomer);
      }
    } else if (isDefined(customerId)) {
      const foundCustomer = user.customers?.find((customer) => customer.customerId === customerId);
      if (foundCustomer) {
        selectCustomer(foundCustomer);
      }
    } else {
      // Each time the user changes, we need to update the selected customer to match the available customer.
      selectCustomer(getSelectedCustomer(user.customers ?? []));
    }
  }, [user, location, navigate, searchParams]);

  const appContextValue = React.useMemo(() => {
    return {
      user: user,
      selectedCustomer,
      setSelectedCustomer: selectCustomer,
      onBeforeSelectedCustomerChange,
      setOnBeforeSelectedCustomerChange,
      vessels,
      location,
    };
  }, [user, selectedCustomer, onBeforeSelectedCustomerChange, vessels, location]);

  const privileges = usePrivileges(user, selectedCustomer);
  const hasAccess = hasElements(intersection([...privileges.global, ...privileges.anyCustomer], HMD_PRIVILEGES));

  const onUserSettingsChange = useCallback(
    (uiSettings: LocalizationSettings) => {
      return API.users.putUsersUsersettings(
        account.tenantId,
        { uiSettings: JSON.stringify(uiSettings) },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    },
    [account.tenantId]
  );

  const onMutate = async (newSettings: LocalizationSettings) => {
    // Optimistic update implemented according to https://tanstack.com/query/v3/guides/optimistic-updates
    // When mutate is called, cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({ queryKey: userQueryKey });

    const previousUsersettings = queryClient.getQueryData<RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto>(userQueryKey);

    if (previousUsersettings) {
      queryClient.setQueryData(userQueryKey, {
        ...previousUsersettings,
        userSettings: JSON.stringify(newSettings),
      });
    }

    return { previousUsersettings };
  };

  const onError = (
    _: unknown,
    __: LocalizationSettings,
    context: { previousUsersettings: RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto | undefined }
  ) => {
    queryClient.setQueryData(userQueryKey, context.previousUsersettings);
  };

  const onSettled = () => {
    queryClient.invalidateQueries({ queryKey: userQueryKey });
  };

  const tenantContextValue = useMemoCompare({ ...tenant, selectCustomer, customer: selectedCustomer, search: location.search });

  if (!hasAccess) {
    return (
      <Result
        status="403"
        title="403"
        subTitle={t('app:authentication.cannotAuthorize')}
        extra={<Button onClick={() => authClient.logout()} label={t('app:appBar.logout')} />}
      />
    );
  }

  // Using all legacy providers for now, but we should be able to use the new context API for the tenant and authentication contexts.
  return (
    <AuthenticationContext.Provider value={account}>
      <TenantContext.Provider value={tenantContextValue}>
        <AppContextProvider value={appContextValue}>
          <FeatureToggles features={activateFeatures([...environment.experimentalFeatures, ...(selectedCustomer.features || [])])}>
            <UserSettingsProvider onChange={onUserSettingsChange} onMutate={onMutate} onError={onError} onSettled={onSettled} user={user}>
              <Outlet />
            </UserSettingsProvider>
          </FeatureToggles>
        </AppContextProvider>
      </TenantContext.Provider>
    </AuthenticationContext.Provider>
  );
};
